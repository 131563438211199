<template>
    <!-- <OpenWorldModal/> -->
<!-- <body class="body-6"> -->
   <div
      class="modalBackground" v-show="nowLoadingModal"
    >
      <div class="modalContainer">
        <img
          src="../assets/BSWIMZ_Mark.svg"
          alt="sequin logo"
          style="height: 60px; width: 60px; margin-top: 78px;"
        >
        <div class="modalText" style="text-align:center;margin-top:25px;">
          Get Ready!
        </div>
        <div
          class="modalText"
          style="margin-top: 11px;"
        >
          BSwimz Rain Alley is now loading...
        </div>
      </div>
    </div>
  <div v-show="showInstall" style="width:1000px;height:375px;background:white;border-radius:5px;z-index:100;position:absolute;margin:auto;top:0px;left:0px;right:0px;bottom:0px;">
    <div style="display:flex;flex-direction:column;">
      <div style="font-family:Montserrat-Bold;font-size:20px;margin-left:0px;margin-top:0px;color:#F07C09;border-bottom: 1px solid #F07C09">
        <div style="height:55px;display: table-cell;vertical-align: middle;padding-left: 20px;">
          Thanks for visiting B Swimz
        </div>
        <img
            src="../assets/exiticon.svg"
            alt="close icon"
            style="position: absolute; right: 17px; top: 17px; cursor: pointer;fill:#F07C09"
            @click="this.showInstall = false"
            >
      </div>
      <div style="display:flex;flex-direction:row;justify-content:space-evenly;align-items: flex-start;height: 300px;">
        <div style="display:flex;flex-direction:column;">
          <div class="numberDiv">
            <div class="modalTextBold headerNumbers">1</div>
          </div>
          <div class="modalText">
            Click <b>RainAlley.msi</b> to run Rain Alley installer, Which just downlaoded via your web browser.
          </div>
           <img src="../assets/RainAlleymsi.png" style="width:200px;margin:auto;margin-top:50px">
        </div>
        <div style="display:flex;flex-direction:column">
          <div class="numberDiv">
            <div class="modalTextBold headerNumbers">2</div>
          </div>
          <div class="modalText">
          Click Yes when prompted by your computer to begin the installation process.
          </div>
           <img src="../assets/SequinWorldInstallerModal.png" style="width:200px;margin:auto;margin-top:40px">
        </div>
        <div style="display:flex;flex-direction:column">
          <div class="numberDiv">
            <div class="modalTextBold headerNumbers">3</div>
          </div>
            <div class="modalText">
          After installation the world will be loaded automatically
          </div>
          <img src="../assets/Done_Icon.svg" style="width:100px;left:0px;right:0px;margin:auto;margin-top:40px;">

          <!-- <div
            id="startButton"
            class="button"
            @click="openDownloadLink"
            style="align-self:center;margin-top:75px;width:200px;background:#F07C09"
            >
            Start
            </div> -->
        </div>
      </div>
    </div>

  </div>
  <div style="position:absolute;margin:auto;top:0px;left:0px;right:0px;bottom:0px;" v-show="showModal" class="modalBackground">
        <div class="modalContainer" >
            <img
            src="../assets/exiticon.svg"
            alt="close icon"
            style="position: absolute; right: 17px; top: 17px; cursor: pointer;fill:#F07C09"
            @click="this.showModal = false"
            >
            <img
            src="../assets/BSWIMZ_Mark.svg"
            alt="sequin logo"
            style="height: 60px; width: 60px; margin-top: 51px;"
            >
            <div class="modalText" style="margin-top:30px;text-align:center">
            You are moments away from entering the metaverse. Download Rain Alley below.
            </div>
            <div
            id="downloadButton"
            class="button"
            @click="openDownloadLink"
            style="align-self:center;margin-top:35px;width:200px;background:#F07C09"
            >
            Download
            </div>
    </div>
  </div>
  <div data-collapse="medium" style="background:white" data-animation="over-right" data-duration="400" data-easing="ease-out-quart" data-easing2="ease-out-quart" data-doc-height="1" role="banner" class="navbar w-nav">
    <div class="navigation" style="background:white">
      <div id="w-node-_86848dd7-6fd8-c29a-d12e-3ec4865cf1a7-865cf1a5" class="nav-left">
        <a href="index.html" class="link-block-8 w-inline-block"><img src="../assets/BSWIMZ_Mark.svg" alt="" style="width:30px" class="logo"></a>
      </div>
      <div class="div-block-245" style="background:white">
        <div class="menu-button-2 w-nav-button" style="background:white">
          <!-- <a href="https://twitter.com/sequin_world" target="_blank" class="link">Twitter</a>
          <a href="https://discord.com/invite/vEQyBsNjyq" target="_blank" class="link">Discord</a>
          <a href="https://www.linkedin.com/company/sequin-world" target="_blank" class="link">LinkedIn</a>
          <a href="https://www.instagram.com/sequin.world/" target="_blank" class="link">Instagram</a>
          <a href="https://sequin.world/partners.html" class="link last">Partners</a>
          <a href="https://sequinworlds.us.auth0.com/u/signup?state=hKFo2SA2Nk1iOEJjRktwSllxSHZVeTBSX19EdkdJZkNZWTU2caFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFg2bzlyQUZ3Vzh2MFBuYU1xMDMtWGs0RVpfNTBRNlNFo2NpZNkgTDVJbUxBQUliYVE3WGlsTW4xSERqN1BQejBTb3owUE4" target="_blank" class="button-4 w-button">Sign Up</a>
          <a href="https://dashboard.sequin.world" target="_blank" class="button-3 login w-button">Log In</a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="world-container wf-section">
    <div class="content-container w-container" style="z-index:1">
      <div class="content-div">
        <h1 class="world-title"><strong class="bold-text-36" style="font-family:Montserrat-Bold;">Rain Alley</strong></h1>
        <p class="world-description">Explore this beautiful Japanese inspired 3D space designed by Maybull Studios. Head down the alley to chill and watch Youtube in the rain, order some takeout, or just explore and walk around as an Avatar of your choice.</p>
        <!-- <p class="current-features"><strong>Current Features:</strong><br>- Connect your wallet and import your NFT collection.<br>- Display your favorite NFTs across 13 canvasses.</p> -->
        <a href="#" class="button-9 explore-interior w-button" style="background:#F07C09;z-index:1000"  @click="exploreClicked">Enter World</a>
        <div class="div-block-270">
          <p id="w-node-b4a2e993-6988-7311-9fb7-76b43fc88d6f-46856408" class="system-requirements"><strong>System Requirements:<br>CPU: </strong>Intel Core i5-2500K@3.3GHz or AMD FX 6300@3.5GHz or higher.<br><strong>RAM: </strong>8GB.<br><strong>OS: </strong>Windows 10 64 bits.<strong><br>GC: </strong>NVidia GeForce GTX 1060 or higher.</p>
          <img class="arrowDiv" src="../assets/Arrow.svg" >
        </div>
      </div>
    </div>
    <video autoplay loop muted preload:auto class="image-background" style="z-index:0"><source  src="../assets/LandingVideo.mp4" type="video/mp4"> </video>
  </div>
  <div style="background:white; display:flex;flex-direction:column;justify-content:space-around;padding-top: 50px;padding-bottom:50px;row-gap: 75px;">
    <div class="bottomRow">
       <img src="../assets/Avatars.gif" class="bottomImage">
       <div class="bottomTextHolder">Choose an Avatar</div>
    </div>
    <div class="bottomRow">
      <div class="bottomTextHolder">Stream Youtube</div>
      <img src="../assets/Youtube.gif" class="bottomImage">
    </div>
    <div class="bottomRow">
       <img src="../assets/Takeout.gif" class="bottomImage">
       <div class="bottomTextHolder">Order Takeout</div>
    </div>
    <div class="bottomRow">
      <div class="bottomTextHolder">Explore the World</div>
      <img src="../assets/ExploretheWorld.gif" class="bottomImage">
    </div>
  </div>
  <div class="hydrogen">
    <h3>Built on Hydrogen</h3>
    <p>Sequin's Hydrogen Metaverse Technology allows for higher fidelity,<br> greater functionality, and better community building on the blockchain.</p>
    <a href="https://www.sequin.world/partners" class="button-9 w-button" style="background:#F07C09;z-index:1000">Learn more</a>
  </div>
  <div class="bottomFooter">
    <img src="../assets/BSwimzWhite.png" style="width:28px;height:52px;margin-left:30px">
    <div class="footerText">
      © 2022 Bswimz. All Rights Reserved.
    </div>
  </div>
    <a
      v-show="false"
      id="openDownloadLink"
      href="https://worlds-assets.s3.amazonaws.com/deployments/standalone/BSwimz.msi"
    />
</template>

<script>
//import OpenWorldModal from './OpenWorldModal.vue'

export default {
  name: 'SingleWorldSushi',
  components: {  },
  props: {
    msg: String
  },
  data () 
  {
      return{
            showModal : false,
            showInstall: false,
            nowLoadingModal: false
      };
      
  },
  mounted() {

    if (window.location.href.endsWith("launch"))
    {
       window.open("sequinworldsalley://", '_self'); 
    }

  },
  methods: {
    exploreClicked() {
       window.open("sequinworldsalley://", '_self');
       this.nowLoadingModal=true;
       setTimeout( () => { this.showModal=false;},15000);
       setTimeout( () => { this.showModal=true;this.nowLoadingModal=false;},3000);
    },
    openDownloadLink() {
        this.showModal=false;
        this.showInstall=true;
        document.getElementById('openDownloadLink').click();
    }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import 'exportedWebflow/css/normalize.css';
@import 'exportedWebflow/css/webflow.css';
@import 'exportedWebflow/css/sequin-world.webflow.css';

.footerText
{
  color:white;
  font-family: 'Montserrat-Light';
  padding-right:30px;
}

@media screen and (max-width: 982px) {
  .arrowDiv{
    display:none;
  }
}

.bottomFooter
{
  height:100px;
  width:100%;
  background:#F07C09;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
}
.bottomRow
{
   display:flex;
   flex-direction:row;
   justify-content:space-around;
   align-items: center;
   align-self: center;
}

@media screen and (max-width: 832px) {
  .bottomTextHolder{
    display:none;
  }
}
.bottomImage
{
   padding: 5px;
   border-radius:10px;
}

.bottomTextHolder
{
    font-family: 'Montserrat-Bold';
    font-size:45px;
    color:#F07C09;
    text-align: center;
    width:639px;
    line-height: 50px;
}

a{
    font-family: 'Montserrat';
}

.navbar {
    background-color: rgba(28, 28, 28,1.0);
}

body{
    font-family: 'Montserrat';
}
h3 {
  margin: 40px 0 0;
  font-family: 'Montserrat';
}
h1{
    font-family: 'Montserrat';
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.numberDiv
{
  position:relative;
  flex:1 0 80px
}
.modalTextBold
{
  font-family:Montserrat-Bold;
  font-size:25px;
  color:#F07C09;
}

.headerNumbers
{
  position:absolute;
  bottom:0px;
  left:0px;
  margin-bottom:20px;
}

  .modalBackground {
    background-color: rgba(40,40,40,0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .modalContainerInstructions {
    height: 480px;
    width: 900px;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: 0 0 10px 0 rgba(28,28,28,0.12);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 900px;
    justify-content: space-around;
  }
  
  .modalText {
    height: 39px;
    width: 292.5px;
    color: #282828;
    font-family: 'Montserrat';
    font-size: 16px;
    letter-spacing: 0.15px;
  }


  .modalContainer {
    height: 300px;
    width: 500px;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: 0 0 10px 0 rgba(28,28,28,0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  
img {
  -webkit-user-drag: none;
  user-select: none;
}

.button {
  height: 40px;
  border-radius: 5px;
  background-color: #603A96;
  color: #FFF;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
  line-height: 17px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.button:hover {
  background-color: #6f43ac;
}

.hydrogen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 450px;
  background-color: #1c1c1c;
  color: #fff;
  text-align: center;
}

.hydrogen > h3 {
    font-size: 36px;
    font-weight: 700;
    font-family: 'Montserrat-Bold';
    margin-bottom: 32px;
    margin-top: 0;
  }

.hydrogen > p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35px;
  }

.hydrogen > a {
    font-size: 20px;
    width: 350px;
    height: 60px;
  }

@font-face {
  font-family: 'Montserrat-Light'; /*a name to be used later*/
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat'; /*a name to be used later*/
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face
{
  font-family: 'Montserrat-Bold'; /*a name to be used later*/
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype'); /*URL to font*/
}
</style>